// PAYMENT CREATION TOP UP AND INVOICES HOOK =  FOR PAYNOW, STRIPE CREDIT CARD, STRIPE CREDIT CARD + 3DS
export const INVOICE_PAYMENT_TYPE = {
  PAYNOW: 6,
  CREDIT_CARD: 1, // STRIPE CARD WITH / WITHOUT 3DS
  CUSTOMER_CREDIT: 7
};

import { apolloClient } from '@/main';
import { PAYMENT_STATUS_ICONS, STRIPE_PAYMENT_STATUS } from '@/modules/b2b/constants';
import { getBatchPaymentDetail } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { DIGITAL_PAYMENT_TYPE_LABEL } from '@/modules/shared/constants/';
import { useConfig, useGetStorageData } from '@/usecases/';
import { priceFormatter } from '@/utils';
import { useBackButton } from '@ionic/vue';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useDateFormatter } from '../../global/useDateFormat';

const useCheckPaymentStatus = () => {
  const { t, storePath, store } = useConfig();
  const { selectedUser } = useGetStorageData();
  const STORE_MODULE_PATH_PAYMENT = `${storePath}/payment`;
  const paymentSummary = computed(() => store.getters[`${STORE_MODULE_PATH_PAYMENT}/paymentSummary`]);
  const paymentStatusVariant = ref({});
  const digitalPaymentStatus = [
    {
      label: t('payment_success'),
      icon: PAYMENT_STATUS_ICONS.SUCCESSFUL,
      color: 'text-primary-green-600',
      btmLabel: t('paid').toUpperCase(),
      value: [STRIPE_PAYMENT_STATUS.APPROVED, STRIPE_PAYMENT_STATUS.SUCCESS]
    },
    {
      label: t('payment_failed'),
      icon: PAYMENT_STATUS_ICONS.FAILED,
      color: 'text-error-600',
      btmLabel: t('failed').toUpperCase(),
      value: [STRIPE_PAYMENT_STATUS.FAILED, STRIPE_PAYMENT_STATUS.REJECTED]
    },
    {
      label: t('payment_pending'),
      icon: PAYMENT_STATUS_ICONS.PENDING,
      color: 'text-primary-orange-500',
      btmLabel: t('pending').toUpperCase(),
      value: [STRIPE_PAYMENT_STATUS.PENDING]
    }
  ];
  const checkPaymentStatus = async () => {
    const { tenant } = await selectedUser.value;
    try {
      const { data } = await apolloClient.query({
        query: getBatchPaymentDetail,
        variables: {
          tenantId: tenant.id,
          batchPaymentId: paymentSummary?.value?.details?.batchPayment?.id
        }
      });
      if (data.getBatchPaymentDetail.status === STRIPE_PAYMENT_STATUS.PENDING) {
        await store.dispatch(`${STORE_MODULE_PATH_PAYMENT}/${ACTIONS.CANCEL_STRIPE_PAYMENT}`, {
          stripePaymentIntentId: data.getBatchPaymentDetail.reference_number
        });
        paymentStatusVariant.value = digitalPaymentStatus[1];
      } else {
        paymentStatusVariant.value = digitalPaymentStatus.find((item) =>
          item.value.includes(data.getBatchPaymentDetail.status)
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  return {
    paymentStatusVariant,
    checkPaymentStatus
  };
};

const useRedirectAction = () => {
  const { storePath, router } = useConfig();

  useBackButton(10, (processNextHandler) => {
    router.replace(`/${storePath}/invoices/select-invoices`);
    processNextHandler();
  });

  const onRedirectToHome = () => {
    router.replace(`/${storePath}/main/home`);
  };

  const onRedirectToInvoices = () => {
    router.replace(`/${storePath}/invoices/select-invoices`);
  };
  return {
    onRedirectToHome,
    onRedirectToInvoices
  };
};

export const useDigitalPaymentProcess = () => {
  const { t, store, storePath, route } = useConfig();
  const { onRedirectToHome, onRedirectToInvoices } = useRedirectAction();
  const { checkPaymentStatus, paymentStatusVariant } = useCheckPaymentStatus();
  const { formatDateMonthYear } = useDateFormatter();
  const { selectedUser } = useGetStorageData();
  const isShowBackdropLoading = ref(false);
  const currency = ref(null);
  const paymentSummaryData = ref({});
  const STORE_MODULE_PATH_PAYMENT = `${storePath}/payment`;
  const paymentTypeId = route.params.paymentTypeId;
  const paymentSummary = computed(() => store.getters[`${STORE_MODULE_PATH_PAYMENT}/paymentSummary`]);
  const selectedInvoices = computed(
    () => store.getters[`${STORE_MODULE_PATH_PAYMENT}/selectedPaymentInvoices`]
  );

  const initializeData = async () => {
    currency.value = await selectedUser.value;
    paymentSummaryData.value = {
      invoices: selectedInvoices.value.selectedInvoices,
      invoicesAmount: selectedInvoices.value.totalInvoiceAmount,
      dataSummary: [
        {
          label: t('applied_credits'),
          value: priceFormatter(currency.value.currency_symbol, paymentSummary.value.totalCustomerBalanceUsed)
        },
        {
          label: t('sub_total'),
          value: priceFormatter(
            currency.value.currency_symbol,
            paymentSummary.value.details.batchPayment.amount
          )
        },
        {
          label: t('total_payment'),
          value: priceFormatter(
            currency.value.currency_symbol,
            paymentSummary.value.details.batchPayment.amount
          )
        },
        {
          label: t('payment_method'),
          value: DIGITAL_PAYMENT_TYPE_LABEL[paymentTypeId]
        },
        {
          label: t('transaction_date'),
          value: formatDateMonthYear(new Date(paymentSummary.value.details.batchPayment.created_at))
        },
        {}
      ]
    };
  };

  onMounted(async () => {
    isShowBackdropLoading.value = true;
    await checkPaymentStatus();
    await initializeData();
    isShowBackdropLoading.value = false;
  });

  onBeforeUnmount(async () => {
    await store.dispatch(`${STORE_MODULE_PATH_PAYMENT}/${ACTIONS.SET_IS_ENTER_STRIPE_URL}`, false);
  });

  return {
    isShowBackdropLoading,
    totalPaidAmount: paymentSummary.value.details.batchPayment.amount,
    paymentSummaryData,
    paymentStatusVariant,
    formatPrice: (price) => priceFormatter(currency?.value?.currency_symbol, price),
    onRedirectToHome,
    onRedirectToInvoices
  };
};
