<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-600 fs-3 pl-2">{{ $t('payment_summary') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true" v-if="!isShowBackdropLoading">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5">
        <div class="pb-5 d-flex align-center justify-center flex-column" v-if="paymentStatusVariant">
          <ion-img :src="paymentStatusVariant?.icon" class="status-icon-width" />
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusVariant?.color">
            {{ paymentStatusVariant?.label }}
          </ion-label>
        </div>
        <ion-grid class="my-2 box px-2 py-2" v-if="paymentStatusVariant">
          <!-- // FOR PAYNOW, DISABLED FOR NOW -->
          <!-- <ion-row class="border-bottom ion-justify-content-center mb-2">
            <ion-text class="text-center mb-2 text-gray-900">{{
              $t('orderB2b.paymentPendingMessage')
            }}</ion-text>
            <ion-col size="12" class="text-center">
              <ion-text class="text-gray-900">{{ $t('orderB2b.paymentPendingHeadline') }}</ion-text>
            </ion-col>
            <ion-col size="12" class="text-center mb-2">
              <ion-text class="fw-600">{{ `${paymentExpiresTime}` }}</ion-text>
            </ion-col>
            <ion-col size="12" class="d-flex justify-center align-center mb-2">
              <ion-icon :icon="timerOutline" class="mr-1 text-error-600 f-icon" ></ion-icon>
              <ion-text class="fw-600 text-error-600"
                >{{ $t('orderB2b.paymentExpireIn') }}
                {{ remainingTime }}
              </ion-text>
            </ion-col>
          </ion-row> -->
          <ion-row class="mb-1 mx-1 ion-justify-content-between">
            <ion-label class="text-gray-700">{{ $t('invoices_amount') }}</ion-label>
            <ion-label style="float: right" class="fw-600 text-gray-700">{{
              formatPrice(totalPaidAmount)
            }}</ion-label>
          </ion-row>
          <ion-row class="mb-1" v-for="(invoice, i) in paymentSummaryData.invoices || []" :key="i">
            <ion-col push="0.3">
              <ion-label class="text-gray-700">{{ invoice.invoiceNumber }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{ formatPrice(invoice.outstandingAmount) }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row
            v-for="(item, idx) in paymentSummaryData.dataSummary"
            :key="idx"
            class="mb-1"
            :class="{ 'border-bottom': [1, 2, 4].includes(idx) }"
          >
            <ion-col>
              <ion-label
                class="text-gray-700"
                :class="{
                  'text-primary-green-600': idx === 0,
                  'fw-700': idx == 5,
                  'fw-500': [1, 2, 0].includes(idx)
                }"
                >{{ item.label }}</ion-label
              >
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-600 text-gray-700" :class="idx === 0 ? 'text-primary-green-600' : ''">
                {{ item?.value }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray-700 fw-500">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-600 text-gray-700" :class="paymentStatusVariant.color">
                {{ paymentStatusVariant.btmLabel }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700"> {{ formatPrice(totalPaidAmount) }}</ion-text>
            </ion-col>
          </ion-row>
          <!-- // FOR PAYNOW, DISABLED FOR NOW -->
          <!-- <div>
            <ion-button class="my-2" @click="$emit('viewQrCode')" expand="block" color="primary">
              <ion-label class="text-capitalize">
                {{ $t('orderB2b.viewQRCode') }}
              </ion-label>
            </ion-button>
            <ion-button class="mb-4" @click="$emit('cancelPaynowPayment')" fill="outline" expand="block">
              <ion-label class="text-capitalize">
                {{ $t('cancel_payment') }}
              </ion-label>
            </ion-button>
          </div> -->
          <div>
            <ion-button class="my-2" @click="onRedirectToInvoices" expand="block" color="primary">
              <ion-label class="text-capitalize">
                {{ $t('back_to_invoice') }}
              </ion-label>
            </ion-button>
            <ion-button class="mb-4" @click="onRedirectToHome" fill="outline" expand="block">
              <ion-label class="text-capitalize">
                {{ $t('back_to_home') }}
              </ion-label>
            </ion-button>
          </div>
        </ion-grid>
      </div>
    </ion-content>
    <ion-loading :is-open="isShowBackdropLoading" cssClass="custom-loading" message="" spinner="crescent">
    </ion-loading>
  </ion-page>
</template>
<script>
import { useDigitalPaymentProcess } from '@/usecases/payment/invoices/';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentSummary',
  setup() {
    const {
      paymentSummaryData,
      isShowBackdropLoading,
      paymentStatusVariant,
      onRedirectToHome,
      onRedirectToInvoices,
      formatPrice,
      totalPaidAmount
    } = useDigitalPaymentProcess();
    return {
      formatPrice,
      isShowBackdropLoading,
      totalPaidAmount,
      paymentSummaryData,
      paymentStatusVariant,
      onRedirectToHome,
      onRedirectToInvoices
    };
  }
});
</script>
<style lang="scss" scoped>
.status-icon-width {
  width: 80px;
}
.f-icon {
  font-size: 1.5rem;
}
ion-content {
  --ion-background-color: #e5e5e5;
}

ion-button {
  width: 97%;
  margin: 0 auto;
  --border-radius: 10px;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 94%;
  margin: 0 auto;
}
</style>
